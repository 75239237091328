import styled from "styled-components"

export const ChangelogList = styled.ul`
  list-style: none;
  margin: 40px 0 0 0;
  padding: 0;

  .version {
    font-weight: 600;
  }
  
  .version-list-item {
    margin: 20px 0 20px 0;
  }
`
