import React from "react"
import StoreLinks from "../store-links/StoreLinks"

function GetTheAppSection() {
  return (
    <>
      <hr className="mb-5 mt-4" />
      <div className="links row">
        <div className="col-12 col-md-6">
          <h5>Get the app</h5>
          <StoreLinks />
        </div>
        <div className="col-12 col-md-6">
          <h5>Or try it now</h5>
          <a
            href="https://play.dashtronaut.app"
            target="_blank"
            rel="noreferrer"
            className="web-app-badge links-badge text-center mt-4"
          >
            <i className="fa-solid fa-arrow-up-right-from-square" />
            <span className="d-inline-block">Launch Web App</span>
          </a>
          <a
            href="https://github.com/Roaa94/flutter-puzzle-hack"
            target="_blank"
            rel="noreferrer"
            className="web-app-badge links-badge text-center mt-4"
          >
            <i className="fab fa-github"/>
            <span className="d-inline-block">The Source Code</span>
          </a>
        </div>
      </div>
      <div className="mb-5" />
    </>
  )
}

export default GetTheAppSection
