import React from "react"
import TutorialsLayout from "../../components/tutorials-layout"
import workingOnItImage from "../../images/working-on-it.png"
import Seo from "../../components/seo"
import { changelogItems } from "../../data"
import ChangelogContent from "../../components/changelog/ChangelogContent"
import GetTheAppSection from "../../components/get-the-app-section/GetTheAppSection"

function ChangelogPage() {
  return (
    <>
      <Seo title="Changelog" titleSuffix="Dashtronaut Tutorials" />
      <h1>Changelog</h1>
      <h4>
        See how Dashtronaut changed since it started, until the submission, and
        beyond that
      </h4>
      <ChangelogContent />
      <GetTheAppSection />
    </>
  )
}

ChangelogPage.Layout = TutorialsLayout

export default ChangelogPage
