import React from "react"
import { changelogItems } from "../../data"
import { ChangelogList } from "./ChangelogContent.styles"

function ChangelogContent(props) {
  return (
    <ChangelogList>
      {changelogItems.map(({ version, items }) => (
        <li className="version-list-item">
          <span className="version">{version}</span>
          <ul>
            {items.map(item => (
              <li>{item}</li>
            ))}
          </ul>
        </li>
      ))}
    </ChangelogList>
  )
}

export default ChangelogContent
