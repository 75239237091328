import React from "react"
import iOSStoreBadge from "../../images/store-badges/ios-app-store-badge.svg"
import macOSStoreBadge from "../../images/store-badges/mac-app-store-badge.svg"
import playStoreBadge from "../../images/store-badges/google-play-badge.png"

function StoreLinks() {
  return (
    <>
      <a
        href="https://apps.apple.com/us/app/dashtronaut/id1609418987"
        target="_blank"
        rel="noreferrer"
        className="app-store-badge mt-4"
      >
        <img
          src={iOSStoreBadge}
          alt="Download Dashtronaut on the iOS App Store"
          width="300"
        />
      </a>
      <a
        href="https://apps.apple.com/us/app/dashtronaut/id1609418987"
        target="_blank"
        rel="noreferrer"
        className="app-store-badge"
      >
        <img
          src={macOSStoreBadge}
          alt="Download Dashtronaut on the MacOS App Store"
          width="300"
        />
      </a>
      <a
        href="https://play.google.com/store/apps/details?id=com.multicaret.flutter.puzzle.hack"
        target="_blank"
        rel="noreferrer"
        className="app-store-badge"
      >
        <img
          src={playStoreBadge}
          alt="Download Dashtronaut on the Android Google Play Store"
          width="300px"
        />
      </a>
    </>
  )
}

export default StoreLinks
